import React from 'react';

import './card.styles.css';

export const Card = props => (
  <div className='card-container'>
    <img
      alt='person'
      src={`${props.person.portrait && props.person.portrait["url"]}`}
    />
    <h2> {props.person.name} </h2>
    <p> {props.person.email} </p>
  </div>
);
