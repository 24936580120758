import React from 'react';

import { Card } from '../card/card';

import './card-list.styles.css';

export const CardList = props => (
  <div className='card-list'>
    {props.persons.map((p, idx) => (
      <Card key={idx} person={p} />
    ))}
  </div>
);
